import React, { useState } from 'react';
import ReactDOM from 'react-dom';

function ChatbotComponent() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="chatbot-container" style={{position: 'fixed', zIndex: 10000, bottom: '20px', right: '20px'}}>
      {/* 채팅 아이콘 */}
      <div className="chat-icon" onClick={toggleChat} style={iconStyle}>
        💬
      </div>
      {/* 채팅창 (아이콘 클릭 시 열림) */}
      {isOpen && (
        <div className="chat-box" style={chatBoxStyle}>
          <div className="chat-header" style={headerStyle}>
            <span>Chatbot</span>
            <button onClick={toggleChat} style={closeBtnStyle}>✖</button>
          </div>
          <div className="chat-body" style={bodyStyle}>
            <p>Hello! This is Deviantce Chat Bot. How can I assist you today?</p>
          </div>
          <div className="chat-footer" style={footerStyle}>
            <input type="text" placeholder="Type a message..." style={inputStyle} />
            <button style={sendBtnStyle}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
}

// 스타일 정의는 이전과 동일하게 유지

// 채팅봇 컴포넌트를 DOM에 렌더링하는 함수
function renderChatbot() {
  const chatbotContainer = document.createElement('div');
  chatbotContainer.id = 'react-chatbot-container';
  document.body.appendChild(chatbotContainer);
  
  ReactDOM.render(<ChatbotComponent />, chatbotContainer);
}

// 전역 객체에 렌더링 함수 추가
window.renderChatbot = renderChatbot;


// 인라인 스타일 설정
const iconStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    fontSize: '3rem',
    cursor: 'pointer',
    zIndex: 1000,
  };
  
  const chatBoxStyle = {
    position: 'fixed',
    bottom: '80px',
    right: '20px',
    width: '300px',
    height: '400px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1000,
  };
  
  const headerStyle = {
    backgroundColor: '#61dafb',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  };
  
  const closeBtnStyle = {
    background: 'none',
    border: 'none',
    fontSize: '1.2rem',
    cursor: 'pointer',
  };
  
  const bodyStyle = {
    flexGrow: 1,
    padding: '10px',
    overflowY: 'auto',
  };
  
  const footerStyle = {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  };
  
  const inputStyle = {
    width: '80%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '5px',
  };
  
  const sendBtnStyle = {
    padding: '8px 12px',
    backgroundColor: '#61dafb',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };
  
  

export default ChatbotComponent;